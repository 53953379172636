<template>
  <div v-if="canAccess('cobranzas_cuentas')">
    <div class="p-field p-fluid p-col-12">
      <ProgressBar
          mode="indeterminate"
          v-if="loading"
          class="p-progressbar progressbar"
      />
    </div>
    <div class="p-grid p-fluid p-col-12 p-pt-5 p-jc-between">
      <div class="p-field p-fluid p-col-12 p-md-3">
        <h5><b>MATRÍCULA: </b>{{ raiseMoney.sequential }}</h5>
      </div>
      <div class="p-field p-fluid p-col-12 p-md-3">
        <h5><b>PLAN: </b>{{ raiseMoney.plan?.name }}</h5>
      </div>
      <div class="p-field p-grid p-col-12 p-md-3">
        <div class="p-fluid ">
          <div class="p-col-fixed">
            <span class="p-float-label">
              <Dropdown
                  id="type_charge"
                  v-model="typeCharge"
                  :options="typeCharges"
                  optionLabel="name"
                  @change="updateTypeCharge()"
              />
              <label class="p-label" for="type_charge"><b>Tipo Cobro</b></label>
            </span>
          </div>
        </div>
      </div>
      <div class="p-field p-fluid p-grid p-col-12 p-md-3">
        <div class="p-field p-fluid p-col-fixed">
          <Button
              label="Exportar"
              icon="pi pi-file-pdf"
              class=" p-button-primary"
              @click="exportReport()"
          />
        </div>
        <div class="p-field p-fluid p-col-fixed">
          <Button
              label="Regresar"
              class=" p-button-danger p-button-outlined"
              @click="$router.push('/raise-money/my-enrollments')"
          />
        </div>
      </div>
    </div>
    <div class="p-grid p-fluid p-col-12">
      <Fieldset
          legend="Pagos de Matrícula"
          :toggleable="true"
          class="card p-col-12 p-fluid"
      >
        <DataTable
            :value="raiseMoney.payments"
            class="p-datatable-responsive-demo p-datatable-sm"
            dataKey="id"
        >
          <Column field="id" header="Id" :sortable="true">
            <template #body="slotSubProps">
              <span class="p-column-title">Id</span>
              <span class="p-column-value">
                {{ slotSubProps.data.id }}
              </span>
            </template>
          </Column>
          <Column field="type_payment" header="Tipo de Pago" :sortable="true">
            <template #body="slotSubProps">
              <span class="p-column-title">Tipo de Pago</span>
              <span class="p-column-value">
                {{ getTypePayment(slotSubProps.data.type_payment) }}
              </span>
            </template>
          </Column>
          <Column field="status" header="Estado" :sortable="true">
            <template #body="slotSubProps">
              <span class="p-column-title">Estado</span>
              <span class="p-column-value">
                {{ getTypePayment(slotSubProps.data.status) }}
              </span>
            </template>
          </Column>
          <Column
              field="type.description"
              header="Medio de Pago"
              :sortable="true"
          >
            <template #body="slotSubProps">
              <span class="p-column-title">Medio de Pago</span>
              <span class="p-column-value">
                {{ slotSubProps.data.type?.description ?? "-" }}
              </span>
            </template>
          </Column>
          <Column
              field="type_transaction"
              header="Tipo Transacción"
              :sortable="true"
          >
            <template #body="slotSubProps">
              <span class="p-column-title">Tipo Transacción</span>
              <span class="p-column-value">
                {{ slotSubProps.data.type_transaction }}
              </span>
            </template>
          </Column>
          <Column field="value" header="Valor" :sortable="true">
            <template #body="slotSubProps">
              <span class="p-column-title">Valor</span>
              <span class="p-column-value">
                ${{ parseFloat(slotSubProps.data.value).toFixed(2) }}
              </span>
            </template>
          </Column>
          <Column field="value_paid" header="Valor Pagado" :sortable="true">
            <template #body="slotSubProps">
              <span class="p-column-title">Valor Pagado</span>
              <span class="p-column-value">
                ${{ parseFloat(slotSubProps.data.value_paid).toFixed(2) }}
              </span>
            </template>
          </Column>
          <Column field="date_payment" header="Fecha de Pago" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Fecha de Pago</span>
              <span class="p-column-value" v-if="slotProps.data.date_payment">
                {{ $filters.dateFormat(slotProps.data.date_payment) }}
              </span>
            </template>
          </Column>
        </DataTable>
      </Fieldset>
      <Fieldset
          legend="Gestión de Cuotas"
          :toggleable="true"
          class="card p-col-12 p-fluid"
      >
        <div class="p-fluid p-grid p-col-12 p-jc-between">
          <div
              class="p-fluid p-field p-grid p-col-12 p-md-6 p-dir-col p-flex-sm-row"
          >
            <span class="p-mr-2">
              <b>EJECUTIVO DE CUENTA: </b>{{ raiseMoney.executive?.name }}
            </span>
            <span>
              <b>ASIGNACIÓN: </b>{{ raiseMoney.executive_account_date }}
            </span>
          </div>
          <div class="p-grid p-fluid p-field p-col-12 p-md-6 p-jc-end">
            <div class="p-col-fixed">
              <Button
                  v-if="
                  ((raiseMoney.amount_paid * 100) /
                    raiseMoney.plan?.total_value ?? 1) < 100
                "
                  icon="pi pi-money-bill"
                  class="p-button-rounded p-button-success"
                  @click="
                  paymentsSidebar = true;
                  country = raiseMoney.contract.country_id;
                "
                  label="Agregar Pagos"
              />
              <label class="p-label" v-else>
                <b style="color: darkred">
                  REGISTRO CANCELADO EN SU TOTALIDAD
                </b>
              </label>
            </div>
          </div>
        </div>
        <DataTable
            :value="raiseMoney.enrollment_fee"
            class="p-datatable-responsive-demo p-datatable-sm"
            dataKey="id"
        >
          <Column field="fee" header="Nro. Cuota" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Nro. Cuota</span>
              <span class="p-column-value">
                {{ slotProps.data.fee }}
              </span>
            </template>
          </Column>
          <Column field="value" header="Valor a Pagar" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Valor a Pagar</span>
              <span class="p-column-value">
                ${{ parseFloat(slotProps.data.value).toFixed(2) }}
              </span>
            </template>
          </Column>
          <Column field="value_paid" header="Valor Pagado" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Valor Pagado</span>
              <span class="p-column-value">
                ${{ parseFloat(slotProps.data.value_paid).toFixed(2) }}
              </span>
            </template>
          </Column>
          <Column field="fee" header="Saldo" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Saldo</span>
              <span class="p-column-value">
                ${{ parseFloat(drag[slotProps.data.fee]).toFixed(2) }}
              </span>
            </template>
          </Column>
          <Column field="date_payment" header="Fecha de Cobro" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Fecha de Cobro</span>
              <span class="p-column-value">
                {{ slotProps.data.date_payment }}
              </span>
            </template>
          </Column>
          <Column field="payments" header="Pagos" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Pagos</span>
              <span class="p-column-value">
                <span :key="i" v-for="(itx, i) in slotProps.data.payments">
                  {{ itx.payment_id }} <Button
                    icon="pi pi-file-o"
                    class="p-button-text"
                    @click="openDialogFiles(itx.payment_id)"/>,
                </span>
              </span>
            </template>
          </Column>
          <Column
              field="value_paid"
              header="Porcentaje"
              style="text-align: center"
              :sortable="true"
          >
            <template #body="slotProps">
              <span class="p-column-title">Porcentaje</span>
              <ProgressBar
                  class="p-column-value"
                  :value="
                  (
                    (slotProps.data.value_paid * 100) / slotProps.data?.value ??
                    1
                  ).toFixed(2)
                "
              />
            </template>
          </Column>
          <Column field="status" header="Estado" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Estado</span>
              <span
                  class="p-column-value p-text-bold status-text"
                  v-if="
                  (slotProps.data.status === 'NO COBRADO' ||
                    slotProps.data.status === 'PARCIALMENTE NO COBRADO' ||
                    slotProps.data.status === 'PARCIALMENTE COBRADO') &&
                    canAccess('cobranzas_admin')
                "
                  @click="openDialogStatus(slotProps.data)"
              >
                {{ slotProps.data.status }}
              </span>
              <div v-else class="p-column-value p-text-bold">
                {{ slotProps.data.status }}
              </div>
            </template>
          </Column>
          <Column field="fee" header="Vigencia" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Vigencia</span>
              <span
                  class="p-column-value"
                  v-html="validity[slotProps.data.fee]"
              ></span>
            </template>
          </Column>
        </DataTable>
      </Fieldset>
      <Fieldset
          legend="Acuerdos de Pago"
          :toggleable="true"
          class="card p-col-12 p-fluid"
      >
        <div class="p-grid p-field p-fluid p-col-12">
          <div class="p-field p-fluid p-grid p-col-fixed">
            <span class="p-float-label">
              <Calendar
                  id="elaboration_date"
                  :showIcon="true"
                  v-model="paymentAgreement"
                  dateFormat="dd/mm/yy"
                  :base-z-index="20000"
                  :yearNavigator="true"
                  yearRange="2021:2050"
              />
              <label class="p-label" for="payment_agreement">
                Fecha Acuerdo Pago
              </label>
            </span>
          </div>
          <div class="p-field p-fluid p-grid p-col-fixed">
            <input-text
                type="textarea"
                v-model="paymentAgreementDescription"
                placeholder="Observacion"
            />
          </div>
          <div class="p-field p-fluid p-grid p-col-fixed">
            <div class="p-col-fixed">
              <Button
                  label="Cancelar"
                  icon="pi pi-times"
                  class="p-button-text"
                  @click="
                  paymentAgreementDescription = '';
                  paymentAgreement = null;
                "
              />
            </div>
            <div class="p-col-fixed">
              <Button
                  label="Agregar"
                  icon="pi pi-check"
                  class="p-button-text"
                  @click="addPaymentAgreement"
              />
            </div>
          </div>
        </div>
        <DataTable
            :value="agreements"
            dataKey="id"
            class="p-datatable-responsive-demo p-datatable-sm"
        >
          <Column
              field="date_agreement"
              header="Fecha Acuerdo Pago"
              :sortable="true"
          >
            <template #body="slotSubProps">
              <span class="p-column-title">Fecha Acuerdo Pago</span>
              <span class="p-column-value">
                {{ getTypePayment(slotSubProps.data.date_agreement) }}
              </span>
            </template>
          </Column>
          <Column field="observation" header="Observación" :sortable="true">
            <template #body="slotSubProps">
              <span class="p-column-title">Observación</span>
              <span class="p-column-value">
                {{ getTypePayment(slotSubProps.data.observation) }}
              </span>
            </template>
          </Column>
          <Column
              field="created_at"
              header="Fecha de Registro"
              :sortable="true"
          >
            <template #body="slotSubProps">
              <span class="p-column-title">Fecha de Registro</span>
              <span class="p-column-value">
                {{ getTypePayment(slotSubProps.data.created_at) }}
              </span>
            </template>
          </Column>
          <template #empty>
            No se encontraron registros.
          </template>
        </DataTable>
      </Fieldset>
      <Fieldset
          :collapsed="false"
          legend="Datos Matrícula"
          :toggleable="true"
          class="card p-col-12 p-fluid"
      >
        <div class="p-grid">
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="sequential"
                  v-model="raiseMoney.sequential"
                  :disabled="true"
              />
              <label class="p-label" for="sequential">Nro. Matrícula</label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="registration"
                  v-model="raiseMoney.registration"
                  :disabled="true"
              />
              <label class="p-label" for="registration">
                Fecha de Registro
              </label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="countryName"
                  v-model="raiseMoneyCountryName"
                  :disabled="true"
              />
              <label class="p-label" for="countryName">País</label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="countryName"
                  v-model="raiseMoneyCityName"
                  :disabled="true"
              />
              <label class="p-label" for="countryName">Ciudad</label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="dateFirstPayment"
                  v-model="raiseMoneyDateFirstPayment"
                  :disabled="true"
              />
              <label class="p-label" for="dateFirstPayment">
                Fecha Primer Pago
              </label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="planName"
                  v-model="raiseMoneyPlanName"
                  :disabled="true"
              />
              <label class="p-label" for="planName">Plan</label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="planCost"
                  v-model="raiseMoneyPlanCost"
                  :disabled="true"
              />
              <label class="p-label" for="planCost">Valor Inscripción</label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="monthMonth"
                  v-model="raiseMoneyMonthMonth"
                  :disabled="true"
              />
              <label class="p-label" for="monthMonth">Mes</label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="weekWeek"
                  v-model="raiseMoneyWeekWeek"
                  :disabled="true"
              />
              <label class="p-label" for="weekWeek">Semana</label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="referralNote"
                  v-model="raiseMoneyReferralNote"
                  :disabled="true"
              />
              <label class="p-label" for="referralNote">Nota Remisión</label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneyBalance"
                  v-model="raiseMoneyBalance"
                  :disabled="true"
              />
              <label class="p-label" for="raiseMoneyBalance">Saldo</label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="referralNote"
                  v-model="raiseMoneyStatus"
                  :disabled="true"
              />
              <label class="p-label" for="referralNote">Estado</label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneyRegistrationOriginDescription"
                  v-model="raiseMoneyRegistrationOriginDescription"
                  :disabled="true"
              />
              <label
                  class="p-label"
                  for="raiseMoneyRegistrationOriginDescription"
              >
                Origen de Matrícula
              </label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneyOrganizationDescription"
                  v-model="raiseMoneyOrganizationDescription"
                  :disabled="true"
              />
              <label class="p-label" for="raiseMoneyOrganizationDescription">
                Organización
              </label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneyEmployeeName"
                  v-model="raiseMoneyEmployeeName"
                  :disabled="true"
              />
              <label class="p-label" for="raiseMoneyEmployeeName">
                Fecha Entrega Materiales
              </label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneySubsidiaryName"
                  v-model="raiseMoneySubsidiaryName"
                  :disabled="true"
              />
              <label class="p-label" for="raiseMoneySubsidiaryName">
                Filial
              </label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneySubsidiaryName"
                  v-model="raiseMoneyIsHolderStudent"
                  :disabled="true"
              />
              <label class="p-label" for="raiseMoneySubsidiaryName">
                ¿El titular és beneficiario?
              </label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneyPlanTotalValue"
                  v-model="raiseMoneyPlanTotalValue"
                  :disabled="true"
              />
              <label class="p-label" for="raiseMoneyPlanTotalValue">
                Costo Total
              </label>
            </span>
          </div>
        </div>
      </Fieldset>
      <Fieldset
          :collapsed="true"
          legend="Datos Personales"
          :toggleable="true"
          class="card p-col-12 p-fluid"
      >
        <div class="p-grid">
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneyContractHolderDni"
                  v-model="raiseMoneyContractHolderDni"
                  :disabled="true"
              />
              <label class="p-label" for="raiseMoneyContractHolderDni">
                Cédula
              </label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneyContractHolderEmail"
                  v-model="raiseMoneyContractHolderEmail"
                  :disabled="true"
              />
              <label class="p-label" for="raiseMoneyContractHolderEmail">
                Email
              </label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneyContractHolderName"
                  v-model="raiseMoneyContractHolderName"
                  :disabled="true"
              />
              <label class="p-label" for="raiseMoneyContractHolderName">
                Nombres
              </label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneyContractHolderLastName"
                  v-model="raiseMoneyContractHolderLastName"
                  :disabled="true"
              />
              <label class="p-label" for="raiseMoneyContractHolderLastName">
                Apellidos
              </label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneyContractHolderPhone"
                  v-model="raiseMoneyContractHolderPhone"
                  :disabled="true"
              />
              <label class="p-label" for="raiseMoneyContractHolderPhone">
                Teléfono
              </label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneyContractHolderMobilePhone"
                  v-model="raiseMoneyContractHolderMobilePhone"
                  :disabled="true"
              />
              <label class="p-label" for="raiseMoneyContractHolderMobilePhone">
                Número de celular
              </label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneyContractHolderCivilStatus"
                  v-model="raiseMoneyContractHolderCivilStatus"
                  :disabled="true"
              />
              <label class="p-label" for="raiseMoneyContractHolderCivilStatus">
                Estado civil
              </label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneyContractHolderBirthDate"
                  v-model="raiseMoneyContractHolderBirthDate"
                  :disabled="true"
              />
              <label class="p-label" for="raiseMoneyContractHolderBirthDate">
                Fecha de nacimiento
              </label>
            </span>
          </div>
        </div>
      </Fieldset>
      <Fieldset
          :collapsed="true"
          legend="Datos Domicilio"
          :toggleable="true"
          class="card p-col-12 p-fluid"
      >
        <div class="p-grid">
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneyContractHolderCountryName"
                  v-model="raiseMoneyContractHolderCountryName"
                  :disabled="true"
              />
              <label class="p-label" for="raiseMoneyContractHolderCountryName">
                País
              </label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneyContractHolderCityName"
                  v-model="raiseMoneyContractHolderCityName"
                  :disabled="true"
              />
              <label class="p-label" for="raiseMoneyContractHolderCityName">
                Ciudad
              </label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneyContractHolderMainStreet"
                  v-model="raiseMoneyContractHolderMainStreet"
                  :disabled="true"
              />
              <label class="p-label" for="raiseMoneyContractHolderMainStreet">
                Calle Principal
              </label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneyContractHolderSecondStreet"
                  v-model="raiseMoneyContractHolderSecondStreet"
                  :disabled="true"
              />
              <label class="p-label" for="raiseMoneyContractHolderSecondStreet">
                Calle Secundaria
              </label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneyContractHolderHouseNumber"
                  v-model="raiseMoneyContractHolderHouseNumber"
                  :disabled="true"
              />
              <label class="p-label" for="raiseMoneyContractHolderHouseNumber">
                Nro. Casa
              </label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneyContractHolderPostalCode"
                  v-model="raiseMoneyContractHolderPostalCode"
                  :disabled="true"
              />
              <label class="p-label" for="raiseMoneyContractHolderPostalCode">
                Código Postal
              </label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneyContractHolderMyTypeHousing"
                  v-model="raiseMoneyContractHolderMyTypeHousing"
                  :disabled="true"
              />
              <label
                  class="p-label"
                  for="raiseMoneyContractHolderMyTypeHousing"
              >
                Tipo de Vivienda
              </label>
            </span>
          </div>
        </div>
      </Fieldset>
      <Fieldset
          :collapsed="true"
          legend="Datos Laborales"
          :toggleable="true"
          class="card p-col-12 p-fluid"
      >
        <div class="p-grid">
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneyContractHolderCompanyName"
                  v-model="raiseMoneyContractHolderCompanyName"
                  :disabled="true"
              />
              <label class="p-label" for="raiseMoneyContractHolderCompanyName">
                Nombre de La Empresa
              </label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneyContractHolderFullCompanyFhone"
                  v-model="raiseMoneyContractHolderFullCompanyFhone"
                  :disabled="true"
              />
              <label
                  class="p-label"
                  for="raiseMoneyContractHolderFullCompanyFhone"
              >
                Teléfono - Extensión
              </label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneyContractHolderCompanyEmail"
                  v-model="raiseMoneyContractHolderCompanyEmail"
                  :disabled="true"
              />
              <label class="p-label" for="raiseMoneyContractHolderCompanyEmail">
                Email de la empresa
              </label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneyContractHolderCompanyAddress"
                  v-model="raiseMoneyContractHolderCompanyAddress"
                  :disabled="true"
              />
              <label
                  class="p-label"
                  for="raiseMoneyContractHolderCompanyAddress"
              >
                Dirección de la empresa
              </label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneyContractHolderMyTypeEmployee"
                  v-model="raiseMoneyContractHolderMyTypeEmployee"
                  :disabled="true"
              />
              <label
                  class="p-label"
                  for="raiseMoneyContractHolderMyTypeEmployee"
              >
                Tipo de empleado
              </label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneyContractHolderDateAdmission"
                  v-model="raiseMoneyContractHolderDateAdmission"
                  :disabled="true"
              />
              <label
                  class="p-label"
                  for="raiseMoneyContractHolderDateAdmission"
              >
                Fecha de Ingreso
              </label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneyContractHolderPosition"
                  v-model="raiseMoneyContractHolderPosition"
                  :disabled="true"
              />
              <label class="p-label" for="raiseMoneyContractHolderPosition">
                Cargo
              </label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneyContractHolderFacebook"
                  v-model="raiseMoneyContractHolderFacebook"
                  :disabled="true"
              />
              <label class="p-label" for="raiseMoneyContractHolderFacebook">
                Facebook
              </label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneyContractHolderSkype"
                  v-model="raiseMoneyContractHolderSkype"
                  :disabled="true"
              />
              <label class="p-label" for="raiseMoneyContractHolderSkype">
                Skype
              </label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneyContractHolderMonthlyIncome"
                  v-model="raiseMoneyContractHolderMonthlyIncome"
                  :disabled="true"
              />
              <label
                  class="p-label"
                  for="raiseMoneyContractHolderMonthlyIncome"
              >
                Ingreso Mensual
              </label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
            <span class="p-float-label">
              <InputText
                  id="raiseMoneyContractHolderObservation"
                  v-model="raiseMoneyContractHolderObservation"
                  :disabled="true"
              />
              <label class="p-label" for="raiseMoneyContractHolderObservation">
                Observación
              </label>
            </span>
          </div>
        </div>
      </Fieldset>
    </div>
    <Sidebar
        v-model:visible="paymentsSidebar"
        :baseZIndex="1000"
        position="right"
        class="card p-field p-fluid p-col-12 p-sm-10 p-xl-6"
    >
      <div class="p-grid p-fluid card">
        <b>REGISTRO DE PAGOS:</b>
      </div>
      <payments
          ref="paymentList"
          v-if="raiseMoney"
          @cancel="paymentsSidebar = false"
          :enrollment="raiseMoney"
          :country="country"
          :edit="true"
      ></payments>
    </Sidebar>
    <Dialog
        v-if="canAccess('cobranzas_admin')"
        v-model:visible="dialogStatus"
        class="size-dialog"
        header="Cambio de Estado a Cuota"
        :modal="true"
    >
      <div class="p-mt-3">
        <Dropdown
            v-model="changeStatusItem"
            :options="status"
            style="width: 100%"
        />
      </div>
      <template #footer>
        <div class="p-mt-3">
          <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-text"
              @click="dialogStatus = false"
          />
          <Button
              label="Guardar"
              icon="pi pi-check"
              class="p-button-text"
              @click="changeStatus"
          />
        </div>
      </template>
    </Dialog>

    <Dialog
        v-if="canAccess('cobranzas_admin')"
        v-model:visible="dialogFiles"
        class="size-dialog"
        header="Documentos del pago"
        :modal="true"
    >
      <div class="p-mt-3">
        <div v-for="(document, index) in documents"  :key="index">
          <label>{{document.name_file}}</label>
          <Button icon="pi pi-download" title="Documentos del pago"
                  class="p-button-info p-mr-2"
                  @click="downloadFile(document.id)"/>  <br/>
        </div>
        <div>
          <span v-if="documents.length === 0">No existen documentos para el pago.</span>
        </div>

      </div>
      <template #footer>
        <div class="p-mt-3">
          <Button
              label="Cerrar"
              icon="pi pi-times"
              class="p-button-text"
              @click="dialogFiles = false"
          />
        </div>
      </template>
    </Dialog>

  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>¡ATENCIÓN!</b></h5>
    <div class="card-body">
      <p class="card-title">
        El acceso a esta opción no se encuentra autorizado
      </p>
      <Button
          label="Regresar"
          class="p-button-danger p-mr-2"
          @click="$router.push('/admin')"
      />
    </div>
  </div>
</template>
<script src="./DetailPayment.js"></script>
<style src="./DetailPayment.scss" lang="scss" scoped></style>
